
import React, { useState } from 'react';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios'
import FuelUnloadingDensityConfig from './FuelUnloadingDensityConfig.component'
import DecantingMSConfig from './DecantingMSConfig.component';
import { convertAmountToWords, preventNonNumericalInput, prevNonNumericalWithDec } from "../../../layouts/Operational_Sidebar_Progress.component";
import { Dropdown } from 'react-bootstrap';




const FuelUnloadingConfig2 = (  unloading, inputValues, dropdown ,isDecantChecked) => {

	console.log({ dropdown},"jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj");
// console.log(unloading.inputValues.tanker,"vvvvvvvvvvvvv")
	const [delete_id, deleteId] = useState([])
	const [errors, setErrors] = useState([])
	const [err, setErr] = useState([])
	const [er, setEr] = useState([])
	const history = useHistory()
	const [unload_product, setUnloadProductId] = useState([])
	const [inputValuess, setInputValuess] = useState([]);
	const [fuel_config, SetFuel] = useState([])
	const [fuel_config1, SetFuel1] = useState([])
	const [data_list, setData] = useState([])
	const [currentPrice, setCurrentPrice] = useState([])
	const [fuel_configs, SetFuelType] = useState([])
	// const [dropDown, setDropdown] = useState([])
	const [fuelData, setFuelData] = useState([])
	const [sellingData, setSellingData] = useState([])
	const [fields, setFields] = useState(['']);
	const [list_data, setList] = useState([])
	const [version, setVersion] = useState([])
	const [fuel, SetNonFuel] = useState([])
	const [success, setSuccess] = useState([])
	const [pumpDetails, setPumpDetails] = useState([
		{ pump_name: '', pump_quantity: '', rent: '' }
	  ]);
	  const [productss, setProduct] = useState([]);
	  

	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			if (isShowing) {
				setInputValuess([''])
				setErrors([''])
				setErr([])
				setEr([])
				setSuccess({})
				setFields([''])
			}
			if (!isShowing) {

				setErrors([''])

			}
			setIsShowing(!isShowing);
		}

		return {
			isShowing,
			toggle,

		}


	};
	useEffect(() => {

		// ListDealership();
		getFuel();

	}, []);

	const { isShowing, toggle } = UseModal();


	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}

	const [search, setSearch] = useState('')
	const handleSearch = (event) => {
		setSearch(event.target.value)
	}



	const [handleText, setHandleText] = useState("")


	const [ChangeHandler, setChangeHandler] = useState(false)
	const inputChangeHandler = (e) => {


		setChangeHandler(true)
		// }
	}



	const handleInput = (i, e) => {


		console.log(e.target.value)
		setInputValuess({
			...inputValuess,
			[e.target.name]: e.target.value,
		});
	};


	//---------------Total PUrchase Price--------------//

	const handleInputChange = (index, e) => {
		const { name, value } = e.target;
		const newPumpDetails = [...pumpDetails];
		newPumpDetails[index][name] = value;
		setPumpDetails(newPumpDetails);
	  };


	
	  const handleAddFields = () => {
		const totalCapacity = parseFloat(unloading.dropdown.total_capacity) || 0;	
		const selectedProducts = inputValuess.product || [];
		const totalInvoiceQuantity = selectedProducts.reduce((sum, product) => {
			return sum + (parseFloat(product.invoice_quantity) || 0); 
		}, 0);
	
		// Check if any pump details are incomplete
		const isIncompletePump = pumpDetails.some(
		  (pump) => !pump.pump_name || !pump.pump_quantity
		);
	  
		if (isIncompletePump) {
		  swal({
			text: "Please fill out all fields in the current pump entry before adding a new one.",
			title: "Incomplete Pump Entry",
			icon: "warning",
			button: "OK",
		  });
		  return; // Prevent adding a new pump detail
		}
	  
		// Calculate the total quantity of pumps
		const totalQuantity = pumpDetails.reduce(
		  (sum, pump) => sum + parseFloat(pump.pump_quantity || 0), 
		  0
		);
		const totalEnteredQuantity = totalInvoiceQuantity + totalQuantity;
		if (totalEnteredQuantity === totalCapacity) {
			swal({
			  text: "The total entered quantity is equal to total capacity. You cannot add more pumps.",
			  title: "Quantity Limit Reached",
			  icon: "warning",
			  button: "OK",
			});
			return; // Prevent adding a new pump detail
		  }
	  
		// // Show the total pump quantity and total capacity
		// swal(`Total Capacity: ${unloading.dropdown.total_capacity}`);
		// swal(`Current Total Quantity: ${totalQuantity}`);
	  
		// If the total quantity equals or exceeds the capacity, show a warning and exit
		if (totalQuantity >= unloading.dropdown.total_capacity) {
		  swal({
			text: "Cannot add more pumps as the total quantity equals or exceeds the total capacity.",
			title: `Total Capacity: ${unloading.dropdown.total_capacity}`,
			icon: "warning",
			button: "OK",
		  });
		  return; // Prevent adding a new pump detail
		}
	  
		// Add a new pump detail if validations pass
		setPumpDetails([
		  ...pumpDetails,
		  { pump_name: '', pump_quantity: '', rent: '' },
		]);
	  };
	  
	  
	  
	  
	  

	  const handleRemoveFields = (index) => {
		const newPumpDetails = pumpDetails.filter((_, i) => i !== index);
		setPumpDetails(newPumpDetails);
	  };

	const handleTotal = (idx, e) => {
		convertAmountToWords(e, e.target.value)
		let product_list = []
		let product = []
		// let product_id = []
		product_list = [...fuel_config]
		if (product_list[idx]) {
			var currentValue = 0
			for (var i = 0; i < product_list.length; i++) {
				var multiple = document.getElementById("amount" + i).value
				currentValue += Number(multiple)
				console.log(currentValue)
				var quantity = document.getElementById("quantity" + i).value
				product.push({
					invoice_quantity: quantity,
					invoice_amount: multiple,

				})

			}
		}
		var litre_quantity = document.getElementById("quantity" + idx).value * 1000
		console.log(litre_quantity)
		var current_price = document.getElementById("amount" + idx).value / litre_quantity
		console.log(current_price)
		console.log(current_price.toFixed(2))
		data_list[idx] = current_price.toFixed(2)
		setData(data_list)
		const nt_amnt = document.getElementById("total")
		var current = currentValue.toFixed(2)
		console.log(current)
		const rounded = Math.round(current);
		console.log(typeof (current))
		if (current % 1 != 0) {
			console.log(rounded)

			var dc
			if (rounded > current) {
				var d = rounded - current
				console.log(d)
				var dec = d.toFixed(2)

				dc = "+" + dec
			}
			else {
				var d = current - rounded
				console.log(d)
				var dec = d.toFixed(2)

				dc = "-" + dec
			}

		}
		else {
			dc = 0
		}
		const r = document.getElementById("round_off")
		r.value = dc

		const a = document.getElementById("net_amount")
		console.log(dc)
		a.value = rounded

		let invoice_amount
		setInputValuess({
			...inputValuess,
			// [e.target.name]: e.target.value,
			total: current,
			round_off: dc,
			net_amount: rounded,
			product: product,


		});



	}
	function getCurrentPrice() {
		var current_price_data = []
		current_price_data = inputValuess.current

		axios(
			{
				url: API_URL + '/convert-price',
				method: "post",
				data: {
					current_price_data
				},
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
					"Accept": "application/json"
				},

			}
		).then(res => {
			let fuelConfigData = [];

		}).catch(error => {
			console.log(error)
		})



	}
	const handleRoundOff = (e) => {

	}

	//

{console.log(unloading.isDecantChecked,"kkkk")}
{console.log(unloading,"kkkk1")}


	//-------------end-------------------//



	function getFuel() {
		const unloading_id = unloading.unloading
		axios(
			{
				url: API_URL + '/unload-list',
				method: "POST",
				data: {
					unloading_id: unloading_id
				},
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
					"Accept": "application/json"
				},

			}
		).then(res => {
			console.log(res.data)
			let fuelConfigData = [];
			res.data.fuel_type.map(fuel_data => {
				console.log(fuel_data.price_per_litre)
				fuelConfigData.push({
					fuel_name: fuel_data.fuel_name,
					id: fuel_data.fuel_id,
					price_per_litre: fuel_data.price_per_litre,
					no_of_tank: null,
					isReadonly: true,
					isRequired: false,
				});

			})
			setVersion(res.data.full_version)
			// setUnloading(res.data.unloading_id)
			SetFuel(fuelConfigData)
			console.log(fuel_config)

		}).catch(error => {
			console.log(error)
		})
	}

	function getFuel1() {
		console.log(inputValuess)
		const unloading_id = unloading.unloading
		axios(
			{
				url: API_URL + '/unload-list',
				method: "POST",
				data: {
					unloading_id: unloading_id,
					product_id: inputValuess.product_id,

				},
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
					"Accept": "application/json"
				},

			}
		).then(res => {
			console.log(res.data)
			let fuelConfigData = [];
			res.data.fuel_type.map((fuel_data, index) => {
				console.log(fuel_data.price_per_litre)
				fuelConfigData.push({
					...fuel_config1,
					fuel_name: fuel_data.fuel_name,
					id: fuel_data.fuel_id,
					tankName: fuel_data.tankName,
					noOfComponent: fuel_data.noOfComponent,
					nozzleData: fuel_data.nozzleData,
					no_of_tank: null,
					isReadonly: fuel_config[index].isReadonly,
					isRequired: fuel_config[index].isRequired,
				});

			})
			setVersion(res.data.full_version)
			SetFuelType({ ...fuel_configs, fuelConfigData });

			// setUnloading(res.data.unloading_id)
			SetFuel1(fuelConfigData)
			console.log(fuel_config)

		}).catch(error => {
			console.log(error)
		})
	}


	function toggleReadOnly(index, e) {
		inputValuess[e.target.name] = { ...inputValuess[e.target.name], [index]: e.target.value };
		setInputValuess(inputValuess)
		let fuelConfigsData = fuel_config;
		if (e.target.checked == false) {
			console.log("open")
			document.getElementById("amount" + index).value = ""
			document.getElementById("quantity" + index).value = ""
			inputValuess.product_id[index] = ""
			if (inputValuess.product) {
				if (inputValuess.product[index].invoice_amount != "") {
					inputValuess.product[index].invoice_amount = ""
				}
				if (inputValuess.product[index].invoice_quantity != "") {
					inputValuess.product[index].invoice_quantity = ""
				}
				console.log(inputValuess)

				setInputValuess(inputValuess)
				let product_list = []
				product_list = [...fuel_config]
				if (product_list[index]) {
					var currentValue = 0
					console.log(inputValuess.product)
					for (var i = 0; i < inputValuess.product.length; i++) {

						console.log(inputValuess.product[i])
						var multiple = inputValuess.product[i].invoice_amount
						console.log(multiple)
						currentValue += Number(multiple)
						console.log(currentValue)
						var quantity = inputValuess.product[i].invoice_quantity


					}
				}
				console.log(document.getElementById("quantity" + index).value)
				var litre_quantity = document.getElementById("quantity" + index).value != "" ? document.getElementById("quantity" + index).value * 1000 : 0
				console.log(currentValue)
				var current_price = document.getElementById("amount" + index).value ? document.getElementById("amount" + index).value / litre_quantity : 0
				console.log(current_price)
				console.log(current_price.toFixed(2))
				data_list[index] = current_price.toFixed(2)
				setData(data_list)
				const nt_amnt = document.getElementById("total")

				var current = currentValue.toFixed(2)
				nt_amnt.value = current

				console.log(current)
				inputValuess.total = current
				setInputValuess(inputValuess)
				const rounded = Math.round(current);
				console.log(typeof (current))
				if (current % 1 != 0) {
					var dc
					if (rounded > current) {
						var d = rounded - current
						console.log(d)
						var dec = d.toFixed(2)

						dc = "+" + dec
					}
					else {
						var d = current - rounded
						console.log(d)
						var dec = d.toFixed(2)

						dc = "-" + dec
					}
					const r = document.getElementById("round_off")
					r.value = dc

					const a = document.getElementById("net_amount")
					console.log(dc)
					a.value = rounded
				}
				else {
					dc = 0
				}
				let invoice_amount
				inputValuess.round_off = dc
				inputValuess.net_amount = rounded
				setInputValuess(inputValuess);

			}
		}
		else {
			console.log("reopen")
			fuelConfigsData[index].no_of_tank = null
			// inputValues[e.target.name] = { ...inputValues[e.target.name], [index]: e.target.value };
			// setInputValues(inputValues)
		}

		console.log(fuelConfigsData[index].isReadonly)
		fuelConfigsData[index].isReadonly = !fuelConfigsData[index].isReadonly
		fuelConfigsData[index].isRequired = !fuelConfigsData[index].isRequired;
		console.log(fuelConfigsData)
		SetFuelType({ ...fuel_configs, fuelConfigsData });
		console.log(fuel_configs)
		console.log(inputValuess)
		setFuelData(inputValuess)
	}
	const [oentnker, setOentnker] = useState({
		own_tanker_rent: ''
	});

	const handleInputss = (e) => {
		const { name, value } = e.target;
		setOentnker((prevState) => ({
			...prevState,
			[name]: value
		}));
	};

	const handleInputs = (e, index, prop) => {

		if (e.target.name == "fuel_type") {
			if (e.target.checked) {
				if (inputValuess[prop] === undefined) {
					inputValuess[prop] = [];
				}
				inputValuess[prop][index] = e.target.value;
			}
			else {

				inputValuess[prop].splice(index, 1);
			}
		}
		else {
			inputValuess[prop] = { ...inputValuess[prop], [index]: e.target.value };
		}


		setInputValuess({
			...inputValuess,
			[e.target.name]: e.target.value,



		});


		//  setLoading(true)

	}
	//------------------------send data-------------------/

	const [showFuelTanker1, setShowFuelTanker1] = useState(false)
	const [showFuelTanker, setShowFuelTanker] = useState(false)

	function validateForm() {
		let formIsValid = true;
		let err = [];
		let er = [];
	
		// Validate fuelConfigsData fields
		fuel_config.forEach((config, i) => {
			if (!config.isReadonly) {
				// Check for empty quantity
				const quantity = document.getElementById("quantity" + i)?.value;
				if (!quantity) {
					formIsValid = false;
					err[i] = "Invoice quantity is required";
				} else {
					err[i] = "";
				}
	
				// Check for empty amount
				const amount = document.getElementById("amount" + i)?.value;
				if (!amount) {
					formIsValid = false;
					er[i] = "Invoice amount is required";
				} else {
					er[i] = "";
				}
			}
		});
	
		// Validate pumpDetails fields
	// 	if (unloading.inputValues.tanker == 1) {
	// 	const newErrors = pumpDetails.map(detail => {
	// 		const errors = {
	// 			pump_name: '',
	// 			pump_quantity: '',
	// 			rent: ''
	// 		};
	
	// 		if (!detail.pump_name) {
	// 			errors.pump_name = 'Pump Name is required';
	// 			formIsValid = false;
	// 		}
	// 		if (!detail.pump_quantity) {
	// 			errors.pump_quantity = 'Quantity is required';
	// 			formIsValid = false;
	// 		}
	// 		if (!detail.rent) {
	// 			errors.rent = 'Rent is required';
	// 			formIsValid = false;
	// 		}
	
	// 		return errors;
	// 	});
	// 	setErrors(newErrors);
	// }
	
		
		setErr(err);
		setEr(er);
	
		return formIsValid;
	}
	
	//------------------------------end----------------//



	///---------------new------------price//

	function getPrice(id, e) {

		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				id: id,
				op_date: localStorage.getItem("op_date")
			}),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},

		};


		fetch(API_URL + "/unload-list", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setInputValuess({ ...inputValuess, responseData })

				return responseData;
			})
			.catch(e => {


			});
	}



{console.log(inputValuess.product,"jjjjjjjjjjjjjjj1")}
	//--------------send Reqst(07/06/2021) -------------//

	function sendData() {
     
		const product = inputValuess.product
		
		setProduct(product);
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({


				product,
				net_amount: inputValuess.net_amount,
				unloading_id: unloading.unloading,
				round_off: inputValuess.round_off,
				total: inputValuess.total,
				product_id: inputValuess.product_id,
				Otherpump:pumpDetails,
				owntankerrent:oentnker.own_tanker_rent,
				owntanker:unloading.inputValues.tanker,
				// dropDown:dropDown.to

			})
		};
		fetch(API_URL + "/unload-amount", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				if (responseData.status == true) {


					swal("", responseData.message, responseData.status ? "success" : "warning")
					// setUnloadProductId(responseData.unload_product_id)
					localStorage.setItem("close", responseData.status);
					setErrors([''])
					if (version == "No") {


						getFuel1()
						setShowFuelTanker(true)
						history.push("/op-fuelunloading")
					}
					else {
						console.log("gdfgdfgfdg")
						// history.push("/op-fuelunloading")
						getFuel1()
						setShowFuelTanker1(true)
						history.push("/op-fuelunloading")
					}
					// toggle();


				}
				let errors = []
				if (responseData.status == false) {
					errors["total"] = responseData.response.total
					errors["round_off"] = responseData.response.round_off
					errors["net_amount"] = responseData.response.net_amount

				}
				if (responseData.status == 3) {
					swal("", responseData.message, responseData.status ? "warning" : "success")
				}

				setErrors(errors)
				return responseData;
			})
			.catch(e => {

			});
	}
	function sendDataotherpump() {
     
		const product = inputValuess.product
		
		setProduct(product);
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({


				// product,
				// net_amount: inputValuess.net_amount,
				unloading_id: unloading.unloading,
				// round_off: inputValuess.round_off,
				// total: inputValuess.total,
				// product_id: inputValuess.product_id,
				Otherpump:pumpDetails,
				otherpump_only: unloading.isDecantChecked ? 1 : 0,
				// owntankerrent:oentnker.own_tanker_rent,
				// owntanker:unloading.inputValues.tanker,
				// dropDown:dropDown.to

			})
		};
		fetch(API_URL + "/saveotherrentonly", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				if (responseData.status == true) {


					swal("", responseData.message, responseData.status ? "success" : "warning")
					// setUnloadProductId(responseData.unload_product_id)
					localStorage.setItem("close", responseData.status);
					setErrors([''])
					if (version == "No") {


						getFuel1()
						setShowFuelTanker(true)
						history.push("/op-fuelunloading")
					}
					else {
						console.log("gdfgdfgfdg")
						// history.push("/op-fuelunloading")
						getFuel1()
						setShowFuelTanker1(true)
						history.push("/op-fuelunloading")
					}
					// toggle();


				}
				let errors = []
				if (responseData.status == false) {
					errors["total"] = responseData.response.total
					errors["round_off"] = responseData.response.round_off
					errors["net_amount"] = responseData.response.net_amount

				}
				if (responseData.status == 3) {
					swal("", responseData.message, responseData.status ? "warning" : "success")
				}

				setErrors(errors)
				return responseData;
			})
			.catch(e => {

			});
	}
	const handleSubmit = (event, index) => {
		
		event.preventDefault();
		
		const selectedProducts = inputValuess.product || [];
		const totalInvoiceQuantity = selectedProducts.reduce((sum, product) => {
			return sum + (parseFloat(product.invoice_quantity) || 0); 
		}, 0);
	
		const d = unloading.dropdown.total_capacity;
	
	
		const totalPumpQuantity = pumpDetails.reduce((sum, pump) => {
			return sum + (parseFloat(pump.pump_quantity) || 0); 
		}, 0);
	
		console.log(totalInvoiceQuantity, "totalInvoiceQuantity");
		console.log(totalPumpQuantity, "totalPumpQuantity");
		
		if (totalInvoiceQuantity + totalPumpQuantity !=d) {  
	
			swal({
				title: "Mismatch Detected",
				text: "The sum of total Entered Capacity should be equal to Tanker Capacity.",
				icon: "warning",
				button: "OK"
			});
			return; // Prevent further code execution if condition is not met
		}
	
		// Continue with the rest of your logic
		setInputValuess({
			...inputValuess,
		});
	
		if (validateForm()) {
			sendData();
		}
	};
	
	const handleSubmit1 = (event, index) => {
		
		event.preventDefault();
		
		
	
		
		setInputValuess({
			...inputValuess,
		});
	
		if (validateForm()) {
			sendData();
		}
	};
	
	
	const handleSubmit2 = (event, index) => {
	
		event.preventDefault(); 
		const selectedProducts = inputValuess.product || [];
		const totalInvoiceQuantity = selectedProducts.reduce((sum, product) => {
			return sum + (parseFloat(product.invoice_quantity) || 0); 
		}, 0);
	
		const d = unloading.dropdown.total_capacity;
	
	
		const totalPumpQuantity = pumpDetails.reduce((sum, pump) => {
			return sum + (parseFloat(pump.pump_quantity) || 0); 
		}, 0);
	
		console.log(totalInvoiceQuantity, "totalInvoiceQuantity");
		console.log(totalPumpQuantity, "totalPumpQuantity");
		
		if (totalInvoiceQuantity + totalPumpQuantity !=d) {  
	
			swal({
				title: "Mismatch Detected",
				text: "The sum of total Entered Capacity should be equal to Tanker Capacity.",
				icon: "warning",
				button: "OK"
			});
			return; // Prevent further code execution if condition is not met
		}
	
		// Continue with the rest of your logic
		setInputValuess({
			...inputValuess,
		});
	 // Prevent the default form submission behavior
		sendDataotherpump(index);  // Pass the index if needed for specific data handling
	  };
	  

	//------------------------------------------//


	return (
		<>

			<h5 className="modal-title" id="exampleModalLabel">Invoice details</h5>
			
			{unloading.inputValues.tanker === 1 && unloading.isDecantChecked ? (
			<div className="row mb-3 " hidden>


				{console.log(fuel_config,"lllll")}
				{
					fuel_config.map((fuelType, idx) => {

						console.log(fuelType.price_per_litre)
						return (<>
							<div className="col-md-4">
								<div className="form-check " >
									<input className="form-check-input" type="checkbox" value={fuelType.id} name="product_id" id={"product_id" + idx} onClick={(event) => { toggleReadOnly(idx, event) }} />
									<label className="form-check-label " htmlFor="flexCheckDefault">{fuelType.fuel_name}</label>
								</div>

								<div className="form-group mb-3 "><label>Invoice Quantity in KL. </label>

									<input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className={`form-control hide-arrow check${fuelType.id}`} name="invoice_quantity" id={"quantity" + idx} onChange={(event) => handleTotal(idx, event)} readOnly={fuelType.isReadonly}></input>
									<div className="errorMsg">{err[idx]}</div>
								</div>

								<div className="form-group mb-3 "><label>Product Wise Invoice Amount </label>
									<input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control hide-arrow amount" onClick={inputChangeHandler} id={"amount" + idx} name="invoice_amount" onChange={(event) => handleTotal(idx, event)} readOnly={fuelType.isReadonly}></input>
									<span id="words" class="amount-words"></span>
									<div className="errorMsg">{er[idx]}</div>
								</div>

								{version == "No" ? '' : ChangeHandler ? <> <div className="form-group mb-3 ">
									<label>Previous Invoice purchase price/Litre : </label>
									{fuelType.isReadonly == true ? <input type="text" className="form-control highlight" readOnly /> : <input type="text" className="form-control highlight" value={fuelType.price_per_litre} readOnly />}{" "}

								</div>
									{console.log(data_list)}
									<div className="form-group mb-3 ">
										<label>current Invoice purchase price/Litre:  </label><input type="text" name="price" className="form-control " value={data_list[idx]} readOnly />{" "}

									</div></> : null}
							</div></>
						)
					})}
			</div>
			) : (
				<div className="row mb-3 ">


				{console.log(fuel_config,"lllll")}
				{
					fuel_config.map((fuelType, idx) => {

						console.log(fuelType.price_per_litre)
						return (<>
							<div className="col-md-4">
								<div className="form-check " >
									<input className="form-check-input" type="checkbox" value={fuelType.id} name="product_id" id={"product_id" + idx} onClick={(event) => { toggleReadOnly(idx, event) }} />
									<label className="form-check-label " htmlFor="flexCheckDefault">{fuelType.fuel_name}</label>
								</div>

								<div className="form-group mb-3 "><label>Invoice Quantity in KL. </label>

									<input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className={`form-control hide-arrow check${fuelType.id}`} name="invoice_quantity" id={"quantity" + idx} onChange={(event) => handleTotal(idx, event)} readOnly={fuelType.isReadonly}></input>
									<div className="errorMsg">{err[idx]}</div>
								</div>

								<div className="form-group mb-3 "><label>Product Wise Invoice Amount </label>
									<input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} className="form-control hide-arrow amount" onClick={inputChangeHandler} id={"amount" + idx} name="invoice_amount" onChange={(event) => handleTotal(idx, event)} readOnly={fuelType.isReadonly}></input>
									<span id="words" class="amount-words"></span>
									<div className="errorMsg">{er[idx]}</div>
								</div>

								{version == "No" ? '' : ChangeHandler ? <> <div className="form-group mb-3 ">
									<label>Previous Invoice purchase price/Litre : </label>
									{fuelType.isReadonly == true ? <input type="text" className="form-control highlight" readOnly /> : <input type="text" className="form-control highlight" value={fuelType.price_per_litre} readOnly />}{" "}

								</div>
									{console.log(data_list)}
									<div className="form-group mb-3 ">
										<label>current Invoice purchase price/Litre:  </label><input type="text" name="price" className="form-control " value={data_list[idx]} readOnly />{" "}

									</div></> : null}
							</div></>
						)
					})}
			</div>
			)}
			{unloading.inputValues.tanker === 1 && unloading.isDecantChecked ? (
			<div className="row " hidden>
			{console.log(inputValuess,"values")}
				<div className="col-md-3">
					<div className="form-group mb-3 ">
						<label>Total <sub className="star">*</sub></label>

						<input type="text" className="form-control " readOnly id={"total"} name="total" value={inputValuess.total} ></input>
						<div className="errorMsg">{errors.total}</div>
					</div>

				</div>
				<div class="col-md-3">
					<div class="form-group mb-3 ">
						<label>Round off<sub className="star">*</sub></label>
						<input type="text" className="form-control " name="round_off" id={"round_off"} readOnly value={inputValuess.round_off} onChange={(e) => handleRoundOff(e)}></input>
						<div className="errorMsg">{errors.round_off}</div>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group mb-3 ">
						<label>Net Amount<sub className="star">*</sub> </label>

						<input type="text" className="form-control  " name="net_amount" id={"net_amount"} readOnly value={inputValuess.net_amount} onChange={(e) => handleRoundOff(e)}></input>
						<div className="errorMsg">{errors.net_amount}</div>
					</div>
				</div>
				{unloading.inputValues.tanker == 1 && (
				<div className="col-md-3">
    <div className="form-group mb-3">
        <label>
            Own Tanker Rent
        </label>
        <input
            type="number"
            className="form-control"
            name="own_tanker_rent"
            id="own_tanker_rent"
            value={oentnker.own_tanker_rent}
            onChange={handleInputss}
        />
    </div>
</div>
)}



{console.log(inputValuess,"nnnnnnnnnnnn")}
			</div>
			):(
<div className="row ">
			{console.log(inputValuess,"values")}
				<div className="col-md-3">
					<div className="form-group mb-3 ">
						<label>Total <sub className="star">*</sub></label>

						<input type="text" className="form-control " readOnly id={"total"} name="total" value={inputValuess.total} ></input>
						<div className="errorMsg">{errors.total}</div>
					</div>

				</div>
				<div class="col-md-3">
					<div class="form-group mb-3 ">
						<label>Round off<sub className="star">*</sub></label>
						<input type="text" className="form-control " name="round_off" id={"round_off"} readOnly value={inputValuess.round_off} onChange={(e) => handleRoundOff(e)}></input>
						<div className="errorMsg">{errors.round_off}</div>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group mb-3 ">
						<label>Net Amount<sub className="star">*</sub> </label>

						<input type="text" className="form-control  " name="net_amount" id={"net_amount"} readOnly value={inputValuess.net_amount} onChange={(e) => handleRoundOff(e)}></input>
						<div className="errorMsg">{errors.net_amount}</div>
					</div>
				</div>
				{unloading.inputValues.tanker == 1 && (
				<div className="col-md-3">
    <div className="form-group mb-3">
        <label>
            Own Tanker Rent
        </label>
        <input
            type="number"
            className="form-control"
            name="own_tanker_rent"
            id="own_tanker_rent"
            value={oentnker.own_tanker_rent}
            onChange={handleInputss}
        />
    </div>
</div>
)}



{console.log(inputValuess,"nnnnnnnnnnnn")}
			</div>
			)}




			<div className="col-md-10">
			</div>

			<div className='row'></div>
			<div className='row'>
			<div className="form-group mb-3 "><label></label></div>
				
{console.log(pumpDetails,"mm")}
			</div>
			
			{unloading.inputValues.tanker === 1 && (
  <div>
    <div className="form-group mb-3">
      <label><h5>Other Pump Details</h5></label>
    </div>
    {pumpDetails.map((detail, index) => (
      <div key={index} className="row">
        <div className="col-md-3">
          <div className="form-group mb-3">
            <label>Pump Name</label>
            <input
              type="text"
              className="form-control"
              name="pump_name"
              value={detail.pump_name}
              onChange={(e) => handleInputChange(index, e)}
            />
            {/* <div className="errorMsg">{errors[index]?.pump_name}</div> */}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group mb-3">
            <label>Quantity in KL</label>
            <input
              type="text"
              className="form-control"
              name="pump_quantity"
              value={detail.pump_quantity}
              onChange={(e) => handleInputChange(index, e)}
            />
            {/* <div className="errorMsg">{errors[index]?.pump_quantity}</div> */}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group mb-3">
            <label>Rent</label>
            <input
              type="text"
              className="form-control"
              name="rent"
              value={detail.rent}
              onChange={(e) => handleInputChange(index, e)}
            />
            {/* <div className="errorMsg">{errors[index]?.rent}</div> */}
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group mb-3">
            <label><sub className="star"></sub></label>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handleRemoveFields(index)}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    ))}
    <button
      type="button"
      className="btn btn-success"
      onClick={handleAddFields}
    >
      + Add Pumps
    </button>
  </div>
)}

			<div className="row">

			
			{/* <div className="container pb-3">
  {unloading.inputValues.tanker == 1 ? (
    <div className="modal-footer justify-content-end p-0">
      <a className="btn btn-primary m-btn w-auto " onClick={handleSubmit}>
        Save/Continue
      </a>
    </div>
  ) : (
    <div className="modal-footer justify-content-end p-0">
      <a className="btn btn-primary m-btn w-auto" onClick={handleSubmit1}>
	  Save/Continue
      </a>
    </div>
  )}
</div> */}
<div className="container pb-3">
  {unloading.inputValues.tanker === 1 && !unloading.isDecantChecked ?(
    <div className="modal-footer justify-content-end p-0">
      <a className="btn btn-primary m-btn w-auto" onClick={handleSubmit}>
        Save/Continue
      </a>
    </div>
  ) : unloading.inputValues.tanker == 2 ? (
    <div className="modal-footer justify-content-end p-0">
      <a className="btn btn-primary m-btn w-auto" onClick={handleSubmit1}>
        Save/Continue
      </a>
    </div>
  ) : unloading.isDecantChecked ? (
    <div className="modal-footer justify-content-end p-0">
      <a className="btn btn-primary m-btn w-auto" onClick={handleSubmit2}>
        Save/Continue
      </a>
    </div>
  ) : null}
</div>


			</div>



			{version == "No" ?
				showFuelTanker ? <div>
					<DecantingMSConfig
						unloading={unloading}
						productid={fuelData}
						fuel_config={fuel_config}
						fuel_config1={fuel_config1}
						
					/>

				</div> : null : showFuelTanker1 == true ? <div>
					<FuelUnloadingDensityConfig
						unloading={unloading}
						productid={fuelData}
						fuel_config={fuel_config}
						fuel_config1={fuel_config1}
					/>

				</div> : null}



		</>
	)
}








export default FuelUnloadingConfig2