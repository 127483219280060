import React, { useState, useEffect } from 'react';
import Sidebar_Progress from "../../../layouts/Admin_Sidebar_Progress.component";
import swal from "sweetalert";
import { API_URL } from "../../../../constant/API_Settings";
import ManagerLoginHeader from '../../Manager/ManagerLoginHeader.component';
import { Pagination } from 'react-bootstrap';
import Loader from '../../Employee/Loader.component';

const AdminPumpsDetailscomponent = (props) => {
    const [pumpsData, setPumpsData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [pages, setPages] = useState({
        current_page: 1,
        total_pages: 1,
    });
    const [loading, setLoading] = useState(false);
    const [abortController, setAbortController] = useState(null);

    useEffect(() => {
        pumpactivity(searchQuery, pages.current_page);
    }, []);

    const pumpactivity = (query = "", page = 1) => {
        setLoading(true);


        if (abortController) {
            abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        const requestOptions = {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
                "Accept": "application/json"
            },
            body: JSON.stringify({ search_keyword: query, page: page }),
            signal: newAbortController.signal 
        };

        fetch(`${API_URL}/activity`, requestOptions)
            .then(response => response.json())
            .then(responseData => {
                setLoading(false);
                if (responseData.status) {
                    setPumpsData(responseData.data.data || []);
                    setPages({
                        current_page: responseData.data.current_page || 1,
                        total_pages: responseData.data.last_page || 1,
                    });
                } else {
                    swal("Error", "Failed to fetch pump activity data", "error");
                }
            })
            .catch(e => {
                if (e.name === 'AbortError') {
                    console.log("Fetch aborted");
                } else {
                    setLoading(false);
                    console.error("Error fetching pump activity:", e);
                }
            });
    };

    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        pumpactivity(query, 1); 
    };

    const handlePagination = (number) => {
        setCurrentPage(number);
        pumpactivity(searchQuery, number); 
    };

    const paginationItems = () => {
        const items = [];
        const totalPages = pages.total_pages;
        const maxPages = Math.ceil(totalPages / 10);

        let start = Math.floor((currentPage - 1) / 10) * 10 + 1;
        let end = Math.min(start + 9, totalPages);

        for (let i = start; i <= end; i++) {
            items.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => handlePagination(i)}
                    style={{
                        backgroundColor: i === currentPage ? '#ADD8E6' : '',
                        borderColor: i === currentPage ? '#ADD8E6' : '',
                    }}
                >
                    {i}
                </Pagination.Item>
            );
        }

        return items;
    };

    const formatTime = (time) => {
        if (!time) return "-";
        const [hours, minutes] = time.split(":");
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${minutes} ${ampm}`;
    };

    const togglePumpStatus = (pumpId, currentStatus) => {
        const newStatus = currentStatus === 1 ? 2 : 1;
        const action = currentStatus === 1 ? "deactivate" : "activate";
        const confirmationMessage = `Do you want to ${action} this pump?`;

        swal({
            title: confirmationMessage,
            icon: "warning",
            buttons: ["Cancel", "Yes"],
            dangerMode: true,
        }).then((willChange) => {
            if (willChange) {
                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
                    },
                    body: JSON.stringify({ id: pumpId, status: newStatus }),
                };

                fetch(`${API_URL}/pumpdeactive`, requestOptions)
                    .then(response => response.json())
                    .then(responseData => {
                        setLoading(false);
                        if (responseData.status) {
                            setPumpsData(pumpsData.map(pump => 
                                pump.id === pumpId ? { ...pump, pump_status: newStatus } : pump
                            ));
                            swal("Success", responseData.msg, "success");
                            pumpactivity(searchQuery, pages.currentPage);
                        } else {
                            swal("Error", responseData.msg || "Failed to update pump status.", "error");
                        }
                    })
                    .catch((e) => {
                        setLoading(false);
                        console.error("Error updating pump status:", e);
                    });
            }
        });
    };

    const handleDeletePump = (pump_id, pump_status) => {
        swal({
          title: "Are you sure?",
          text: "Do you want to remove this pump?",
          icon: "warning",
          buttons: ["Cancel", "Yes"],
          dangerMode: true,
        }).then((firstConfirm) => {
          if (firstConfirm) {
            swal({
              title: "Warning!",
              text: "All data related to this pump will be permanently removed. Are you sure?",
              icon: "warning",
              buttons: ["Cancel", "Yes, remove it"],
              dangerMode: true,
            }).then((secondConfirm) => {
              if (secondConfirm) {
                setLoading(true); 
      
                const requestOptions = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN"),
                  },
                  body: JSON.stringify({ id: pump_id }),
                };
      
                fetch(`${API_URL}/pumpdelete`, requestOptions)
                  .then((response) => {
                    if (!response.ok) {
                      throw new Error("Failed to deactivate the pump.");
                    }
                    return response.json();
                  })
                  .then((data) => {
                    setLoading(false); 
                    if (data.status) {
                      swal("Success", data.msg, "success");
                      pumpactivity(searchQuery, pages.currentPage);
                    } else {
                      swal("Error", data.msg, "error");
                    }
                  })
                  .catch((error) => {
                    setLoading(false); 
                    swal("Error!", error.message || "An unexpected error occurred.", "error");
                  });
              }
            });
          }
        });
      };
      
    return (
        <div className="main-container page-wrap">
            <div className="d-md-flex">
                <Sidebar_Progress props={props} progress={21} />
                <div className="content-area">
                    <ManagerLoginHeader props={props} page_title="Pumps Activity" />
                    <div className="container pb-3">
                        <div className="row mt-md-5 mt-3 form-container">
                            <div className="form-wrap">
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active">
                                        <div className="row mb-3">
                                            <div className="col-md-8">
                                                <h3 className="table-title">Pumps Activity</h3>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="input-group mb-3 search">
                                                    <span className="input-group-text" id="basic-addon1">
                                                        <i className="fa fa-search" aria-hidden="true"></i>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search"
                                                        value={searchQuery}
                                                        onChange={handleSearch}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {loading ? (
                                            <div className="d-flex justify-content-center">
                                                <Loader />
                                            </div>
                                        ) : (
                                            <div className="table-widget">
                                                <table className="table border col-12">
                                                    <thead>
                                                        <tr>
                                                            <th>Pump Name</th>
                                                            <th>Last Active User</th>
                                                            <th>Active Date</th>
                                                            <th>Active Time</th>
                                                            <th>Address</th>
                                                            <th>Email</th>
                                                            <th>Phone</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {pumpsData.map((pump, index) => (
                                                            <tr key={index}>
                                                                <td>{pump.pump_name || "-"}</td>
                                                                <td>{pump.user_name || "-"}</td>
                                                                <td>{pump.active_date || "-"}</td>
                                                                <td>{formatTime(pump.active_time)}</td>
                                                                <td
                                                                   style={{
                                                                    flex: '1',
                                                                    textAlign: 'center',
                                                                    wordWrap: 'break-word',
                                                                    maxWidth: '150px', 
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis', 
                                                                  }}
                                                                    title={pump.pump_address || "-"}>
                                                                    {pump.pump_address || "-"}
                                                                </td>
                                                                <td>{pump.pump_email || "-"}</td>
                                                                <td>{pump.pump_phone || "-"}</td>
                                                                <td>
  <button
    style={{
      background: pump.pump_status === 1 ? '#06cd8b' : '#ff4d4d',
      color: '#fdfffe',
      fontSize: '13px',
      marginRight: '10px', 
    }}
    className="btn mt-2"
    onClick={() => togglePumpStatus(pump.pump_id, pump.pump_status)}
  >
    {pump.pump_status === 1 ? "Active" : "Deactive"}
  </button>
  <button
    style={{
      backgroundColor: '#dc3545', 
      color: '#fff',
      fontSize: '13px',
    }}
    className="btn mt-2"
    onClick={() => handleDeletePump(pump.pump_id, pump.pump_status)}
  >
    Delete
  </button>
</td>

          
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
    <Pagination size="sm">
        <Pagination.Prev
            onClick={() => handlePagination(currentPage - 1)}
            disabled={currentPage === 1}
        />
        {paginationItems()}
        <Pagination.Next
            onClick={() => handlePagination(currentPage + 1)}
            disabled={currentPage === pages.total_pages}
        />
    </Pagination>
</div>

                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminPumpsDetailscomponent;
