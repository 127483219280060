import { Component } from "react";
import logo from '../../img/logo.svg';
import avatar from '../../img/avatar.svg'
import dashboard from '../../img/dashboard.png'
import settings from '../../img/settings.png'
import user from '../../img/operation.png'
import operation from '../../img/operation.png'
import activity from '../../img/activity.png'
import fuelstation from '../../img/fuel-station.png'
import purchasing from '../../img/purchasing.png'
import dispenser from '../../img/dispenser.png'
import fueltruck from '../../img/fuel-truck.png'
import scale from '../../img/scale.png'
import fuel from '../../img/fuel.png'
import tank from '../../img/tank.png'
import { Link, useLocation } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';
import { WEB_URL } from "../../constant/API_Settings"

import { API_URL } from "../../constant/API_Settings"
const Admin_Sidebar_Progress = (progress, operation) => {
  const location_details = useLocation()
  

  const [show7, setShow7] = useState(false)
  // console.log(progress)
  useEffect(() => {
    check_meta_date();

  }, []);
  function check_meta_date() {

    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      }
    };
    fetch(`${API_URL}/check_meta_date`, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        // this.setState({ op_date:responseData.op_date,userId:responseData.userId})
        setUser({ user_id: responseData.userId })
        setRole({ role_id: responseData.role_id })
        return responseData;
      })
      .catch(e => {
      });

  }

  const [user, setUser] = useState()
  const [role_id, setRole] = useState()
  const [userName, setUserName] = useState(localStorage.getItem("PROFILE_NAME"))
  return (
    <div className="left-wrap p-0">
      <div className="d-flex">
        <div className="logo-wrp">
          <a className="logo-continer text-center" href="#"><img src={logo} /></a>
        </div>

      </div>
      <div class="responsive">
        <div class="profile-pic">
          <img src={avatar} class="img-fluid" />
        </div>

      </div>

      <ul className="sidemenu">

      <li className={progress.progress == 18 ? "active" : ""}>
          <Link to={"/admin_dashboard"}>
            <a href="/admin_dashboard">
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                </svg><span class="ms-2">Dashboard</span>
              </p></a></Link>
        </li> 
        <li className={progress.progress == 23 ? "active" : ""}>
          <Link to={"/admin-pumps-details"}>
            <a href="/admin-pumps-details">
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                </svg><span class="ms-2">Pumps Acivity</span>
              </p></a></Link>
        </li>
        <li className={progress.progress == 19 ? "active" : ""}>
          <Link to={"/pump_name"}>
            <a href="/pump_name">
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                </svg><span class="ms-2">Set Pump</span>
              </p></a></Link>
        </li>
        <li className={progress.progress == 20 ? "active" : ""}>
          <Link to={"/admin-role-list"}>
            <a href="/admin-role-list">
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                </svg><span class="ms-2">Role management</span>
              </p></a></Link>
        </li>
        <li className={progress.progress == 20 ? "active" : ""}>
          <Link to={"/permission"}>
            <a href="/permission">
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                </svg><span class="ms-2">Permission management</span>
              </p></a></Link>
        </li>
        <li className={progress.progress == 21 ? "active" : ""}>
          <Link to={"/admin-subscription-management"}>
            <a href="/admin-subscription-management">
              <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-fill" viewBox="0 0 16 16">
                <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
              </svg><span class="ms-2">Subscription Management</span></p></a></Link>
        </li>


        <li>

          <a onClick={() => setShow7(!show7)} class="text-white" href="#">
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg><span class="ms-2">About us</span></p></a>
          {show7 ?
            <ul className="sidesubmenu">

              <li>
                <Link>
                  <a class="dropdown-toggle" class="let"><p>
                    <span class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>About PetroSmart</span></p></a></Link>

              </li>
              <li>
                <Link>
                  <a href="#page5" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle let"><p>
                    <span class="ms-5"><i class="fa fa-angle-double-right " aria-hidden="true"></i>Privacy policies</span></p></a>
                </Link>
              </li>
            </ul>
            : <a onClick={() => setShow7(show7)}></a>}
        </li>

      </ul>
      {/* <a href="#" class="btm-corner"><strong><i className="fa fa-question-circle-o p-2"></i>Help</strong></a> */}
    </div>
  )


}


export default Admin_Sidebar_Progress;
export const convertAmountToWords = (e, nums) => {
  var a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
  var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
  var myArr = nums.split(".");
  var num = myArr[0];
  var num1 = myArr[1] ? myArr[1] : '';
  if ((num = num.toString()).length > 9) return 'overflow';
  /****************************/
  var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

  if (!n) return; var str = '';
  str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
  str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
  str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
  str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
  str += (n[5] != 0) ? ((str != '') ? 'And ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + ' ' : '';
  // var selector = document.getElementsByClassName('amount-words')
  /************************* */
  if (num1 != '') {
    if ((num1 = num1.toString()).length > 2) return 'overflow';
  }

  /****************************/
  var p = ('000000000' + num1).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  console.log(p);
  var str1 = '';
  if ((p) && (num1 != '')) {
    str1 += (p[5] != 0) ? ((str1 != '') ? 'And ' : '') + (a[Number(p[5])] || b[p[5][0]] + ' ' + a[p[5][1]]) + ' ' : '';
  }
  /************************* */
  var parent = e.currentTarget.parentNode
  var selector = parent.querySelector('.amount-words')
  if (typeof selector !== 'undefined' && selector) {
    if(str1 !='')
		{
			 if(str =='')
			 {
				 str = 'Zero';
			 }
		str = str+' Rupees And '+str1+' Paise ';
		}
		if(str !='' && num != 1 && num != 0){
		str = str+' Rupees';
		}
		else if(num == 0 && str1 =='')
		{
		  str = str+' Zero';
		}
		else if(num == 1 && str1 =='')
		{
		  str = str+' Rupee';
		}	
    selector.innerHTML = str;
  }


}

export const preventNonNumericalInput=(e,num) =>{
  
  e = e || window.event;
  var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
  var charStr = String.fromCharCode(charCode);
  var fullChar = num ? num?.toString() + charStr : charStr;
  if (!fullChar.match(/^[0-9]*(\.{1})?([0-91-9][0-9])?$/))
  e.preventDefault();

  var myArr =fullChar.toString().split('.');
  var num0 = myArr[0];
  if ((num0 = num0.toString()).length > 8)
  e.preventDefault();
  }

  export const prevNonNumericalWithDec=(e,num) =>{

   var parent = e.currentTarget.parentNode
   var selector = parent.querySelector('.ph_no')
   if (typeof selector !== 'undefined' && selector)
     {
       e = e || window.event;
       var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
       var charStr = String.fromCharCode(charCode);
       var fullChar = num ? num?.toString() + charStr : charStr;
       if (!fullChar.match(/^[0-9]+$/))
       e.preventDefault();
     }
     else
     {
       e = e || window.event;
       var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
       var charStr = String.fromCharCode(charCode);
       var fullChar = num ? num?.toString() + charStr : charStr;
       if (!fullChar.match(/^[0-9]+$/))
       e.preventDefault();

       var myArr =fullChar.toString().split('.');
       var num0 = myArr[0];
       if ((num0 = num0.toString()).length > 8)
       e.preventDefault();

     }
   }