
import React, { useState } from 'react';
import LoginHeader from "../../../layouts/LoginHeader.component";
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactTooltip from 'react-tooltip'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import axios from 'axios';
import {convertAmountToWords,preventNonNumericalInput} from "../../../layouts/Operational_Sidebar_Progress.component";

// {}
const DensityAfterUnloadConfig = (unloading) => {
console.log(unloading)
	const [delete_id, deleteId] = useState([])
	const [version, setVersion] = useState([])
	const [inputValues, setInputValues] = useState([]);
	const [fields, setFields] = useState([]);
	const history = useHistory()
	const [list_data, setList] = useState([])
	const [inputList, setInputList] = useState([])
	// const [unloading,setUnloading] = useState([]) 
	const [fuel, SetFuel] = useState([])


	const [errors, setErrors] = useState([])
	const [success, setSuccess] = useState([])
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			if (isShowing) {
				setInputValues([''])
				setErrors([''])
				setSuccess({})
				setFields([''])
			}
			if (!isShowing) {

				setErrors([''])

			}
			setIsShowing(!isShowing);
		}

		return {
			isShowing,
			toggle,
		}

	};


	const { isShowing, toggle } = UseModal();
	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}
	useEffect(() => {
		getFuel();

	}, [unloading]);
	function getFuel() {
		var unloading_id
		var product_id = []
		if(unloading?.unloading?.unloading?.unloading?.unloading)
		{
		 unloading_id = unloading.unloading.unloading.unloading.unloading
		 product_id = unloading.unloading.unloading.productid.product_id
		}
		else{
			unloading.unloading.map((fuel)=>{
				unloading_id = fuel.unloading_id
				product_id.push(fuel.product_id)
			})
		
		}
		axios(
			{
				url: API_URL + '/unload-list',
				method: "post",
				data: {
					unloading_id: unloading_id,
					product_id:product_id,
					isAfterUnloading:true
				},
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
					"Accept": "application/json"
				}
			}
		).then(res => {
			let fuelConfigData = [];
			res.data.fuel_type.map(fuel_data => {
				fuelConfigData.push({
					fuel_name: fuel_data.fuel_name,
					id: fuel_data.fuel_id,
					tankName: fuel_data.tankName,
					noOfComponent: fuel_data.noOfComponent,
					nozzleData: fuel_data.nozzleData,
					no_of_tank: null,
					isReadonly: true,
					isRequired: false,
				});
			})
			setVersion(res.data.full_version)
			// setUnloading(res.data.unloading_id)
			SetFuel(fuelConfigData)
			setList(fuelConfigData)


		}).catch(error => {
			console.log(error)
		})
	}
	const [showFuelTanker, setShowFuelTanker] = useState(false)
	
	const handle = (idx, index, e, prop) => {
   var unloading_id
		if(unloading?.unloading?.unloading?.unloading?.unloading)
		{
		 unloading_id = unloading.unloading.unloading.unloading.unloading
		
		}
		else{
			unloading.unloading.map((fuel)=>{
				unloading_id = fuel.unloading_id
				
			})
		
		}
		const hmv = document.getElementById("hmv" + index + idx).value
		console.log(document.getElementById("hmv" + index + idx).value)
		const temp = document.getElementById("temp" + index + idx).value
		console.log(hmv)
		console.log(temp)
		getDensity()
		async function getDensity() {

			const requestOptions = {
				method: 'post',

				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
					"Accept": "application/json"

				}, body: JSON.stringify({
					 unloading_id: unloading_id, 
					 product_id: inputValues.product_id,
					  hmv: hmv, temp: temp }),
			};
			await fetch(API_URL + "/observed-data", requestOptions)
				.then(response => { return response.json(); })
				.then(responseData => {
					if (responseData.status === true) {
						var op = responseData?.observed_density;
						console.log(op)
						var s = document.getElementById("observed_density" + index + idx);
						s.value = op;
					}
					let errors = []
					if (responseData.status === false) {
						
						swal("", responseData.message, responseData.status ? "success" : "warning")
					}

					setErrors(errors)

				})
				.catch(e => {
					console.log(e);

				});

		}

console.log(inputValues)
    var fuelData = []
		 fuelData = [...list_data]
		for (var i = 0; i < fuelData[index].tankName.length; i++) {
			if (e.target.name == "temp_after_unload") {
				fuelData[index].tankName[idx]["temp_after_unload"] = e.target.value
			}
		}
		for (var i = 0; i < fuelData[index].tankName.length; i++) {
			console.log(e.target.value)
			fuelData[index].tankName[idx]["hmv_after_unload"] = document.getElementById("hmv" + index + idx).value

		}
		console.log(fuelData)


		fuelData['product_id'] = { ...fuelData['product_id'], [idx]: fuelData[idx]?.id };
		if(unloading?.unloading?.unloading?.unloading?.unloading)
		{
			fuelData["unloading_id"] = unloading.unloading.unloading.unloading.unloading
		}
		else{
			unloading.unloading.map((fuel)=>{
				fuelData["unloading_id"] = fuel.unloading_id
			
			})
		
		}
		
		setInputValues(fuelData)
        setList(fuelData)

	}
	const handleSubmit = () => {
		console.log(inputValues)
			sendData()
	
		}
	
	function sendData() {
		var unloading_id
		if(unloading?.unloading?.unloading?.unloading?.unloading)
		{
		 unloading_id = unloading.unloading.unloading.unloading.unloading

		}
		else{
			unloading.unloading.map((fuel)=>{
				unloading_id = fuel.unloading_id
			
			})
		
		}
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
			    input:inputValues,
				unloading_id:unloading_id
			})
		};
		fetch(API_URL + "/store-decanting-density", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})

				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/op-fuelunloading")
					setErrors([''])
					// setInputValues([])



				}
				var status = responseData.status == true ? true : false
				setFields(status)
				let errors = []
				if (responseData.status == false) {
					errors["hmv_after_unload"] = responseData.response.hmv_after_unload
					errors["tank_id"] = responseData.response.tank_id
					errors["temp_after_unload"] = responseData.response.temp_after_unload
					errors["product_id"] = responseData.response.product_id
					

				}
				if (responseData.status == 5) {
					swal("", responseData.message, responseData.status ? "warning" : "warning")
                    // setInputValues([])
				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {

			});
	}



	
	function printPage() {
		var unloading_id
		if(unloading?.unloading?.unloading?.unloading?.unloading)
		{
		 unloading_id = unloading.unloading.unloading.unloading.unloading
	
		}
		else{
			unloading.unloading.map((fuel)=>{
				unloading_id = fuel.unloading_id
			
			})
		
		}
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				unloading_id: unloading_id
			})
		};
		fetch(API_URL + "/download_fuel-unload", requestOptions)
			.then(response => { return response.blob(); })
			.then(responseData => {
				var blob = new Blob([responseData]);
				var link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = "Fuel_unload.pdf";
				link.click();
				setShowFuelTanker(true)
				return responseData;
			})
			.catch(e => {
				console.log(e);
			});
	}








	return (
		<>
			<h5 class="modal-title" id="exampleModalLabel">Density After Unloading and Dispensing 50 LTr</h5>

						<div class="row form-container">
							{fuel.map((fuelConfig, index) => {

								return (
									<>
									{fuelConfig.tankName.length == 0 ?"":
									<>
											<div class="">
													<div  class="tab-pane fade show mt-5 active" id="" role="tabpanel" aria-labelledby="existinguser-tab">
														
														<div class="row mt-3 ">

															<div class=" py-2  col-md-6"><p>Product<sub className="star">*</sub>
															</p></div>

															<div class="col-md text-center pe-2 ">
																<input type="text" class=" form-control" readOnly value={fuelConfig.fuel_name}></input>
																<div className="errorMsg">{errors.product_id}</div>
															</div>
														</div>
														<div class="row ">

															<div class=" py-2  col-md-6"><p>Tank Name<sub className="star">*</sub></p></div>
															{fuelConfig.tankName.map((tank, index) => {
																return (
																	<>
																		<div class="col-md text-center pe-2 ">
																			<input type="text" class=" form-control " readOnly value={tank.tank_name}></input>
																			<div className="errorMsg">{errors.tank_id}</div>
																		</div>
																	</>
																)
															})
															}
														</div>

														<div class="row ">
															<div class=" py-2  col-md-6 mx-0"><p>HMV <sub className="star">*</sub> </p></div>
															{fuelConfig.tankName.map((tank, idx) => {
																return (
																	<>
																		<div class="col-md text-center  pe-2 ">
																			<input type="number" class="form-control  hide-arrow"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} placeholder="eg:663" name="hmv_after_unload" id={"hmv" + index + idx} onBlur={(e) => handle(idx, index, e, e.target.name)}></input>
																			<div className="errorMsg">{errors.hmv_after_unload}</div>
																		</div>
																	</>
																)
															})
															}
														</div>
														<div class="row ">

															<div class=" py-2  col-md-6 mx-0"><p>Temperature <sub className="star">*</sub> </p></div>
															{fuelConfig.tankName.map((tank, idx) => {
																return (
																	<>
																		<div class="col-md text-center pe-2 ">
																			<input type="number"   onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} class="form-control hide-arrow " placeholder="eg:6" name="temp_after_unload" id={"temp" + index + idx} onBlur={(e) => handle(idx, index, e, e.target.name)}></input>
																			<div className="errorMsg">{errors.temp_after_unload}</div>

																		</div>
																	</>
																)
															})
															}
														</div>
														
															<div class="row ">
																<div class=" py-2  col-md-6 mx-0"><p> Density  </p></div>
																{fuelConfig.tankName.map((tank, idx) => {
																	return (
																		<>
																			<div class="col-md text-center pe-2 ">
																				<input type="text" class="form-control amount" name="density_after_unload" id={"observed_density" + index + idx} readOnly onChange={(e) => handle(idx, index, e, e.target.name)}></input>
																			</div>
																		</>
																	)
																})
																}
															</div>
													</div>
												</div>
											<div class="col-md-10">


											</div>
					

										

</>
															}
									</>
								)
							})}
							<div className="modal-footer justify-content-end p-0 "><a className="btn btn-primary m-btn w-auto " onClick={handleSubmit}  >save/Continue</a></div>
						</div>

					
                 	<p className="modal-footer justify-content-end" style={{color:"#5d38dbeb"}}>
					After successfull this save, Please 
					<u><a href="#closemodal">click here</a></u>to close
					</p>
			
			
			
			{unloading?.unloading?.unloading?.unloading?.unloading &&
			<div className="row justify-content-center">
				<div class="col-md-3 modal-footer">
					{fields == true ? <a onClick={printPage} className="btn btn-success  mb-2"  >print</a> : ""}</div>
			</div>
}

		</>
	)
}








export default DensityAfterUnloadConfig